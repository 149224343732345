<template>
  <div class="page-bg">
    <div class="demo-main">
      <el-breadcrumb class="breadcrumb" separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/demo' }">Demo</el-breadcrumb-item>
        <el-breadcrumb-item>Phoneme speech assessment</el-breadcrumb-item>
      </el-breadcrumb>
      <div class="title">Speech Assessment of English Phoneme</div>
      <div class="sub-title">Please click the microphone and read aloud the following phoneme.</div>
      <div v-if="startSoe" class="countdown">{{ second }}s</div>
      <div class="demo-container">
        <div class="ref-text-part">
          <div class="ref-text-content">
            <div class="ref-text">
              <span v-if="!hasResult">{{ selectPhonemeData[0].dj }}</span>
              <span v-if="hasResult" :class="overall < 80?'non':'standard'">{{ selectPhonemeData[0].dj }}</span>
              <img v-if="!ifplayAudio" @click="playAudio" src="~@/assets/images/soe_demo/demo/play_audio.png" alt="">
              <img v-if="ifplayAudio" @click="stopAudio" src="~@/assets/images/soe_demo/demo/stop_audio.png" alt="">
              <audio ref="audioElement" @ended="onAudioEnded" :src="selectPhonemeData[0].audio"></audio>
            </div>
          </div>
          <div class="ref-text-tip">
            <p>Pronunciation tips: {{ selectPhonemeData[0].gist }}</p>
            <p>Common words: {{ selectPhonemeData[0].words }}</p>
          </div>
        </div>
        <div class="btn-try-next-box">
          <el-button @click="getRandomArrayElements(phonemeDataList, 1)" v-if="recorderStatus === 1" type="primary" plain>Try another one</el-button>
        </div>
        <div class="btn-recorder">
          <div @click="startEvaluation" v-if="recorderStatus === 1" class="recorder-box"><img src="~@/assets/images/soe_demo/demo/reg_start.png" alt=""></div>
          <div v-if="recorderStatus === 0" class="recorder-box recorder-box-loading">
            <ul class="loading-taste-line">
              <li class="item"></li>
              <li class="item"></li>
              <li class="item"></li>
              <li class="item"></li>
              <li class="item"></li>
            </ul>
          </div>
          <div @click="stopEvaluation" v-if="recorderStatus === 2" class="recorder-box-stop">
            <ul class="start-taste-line">
              <li class="hr1"></li>
              <li class="hr2"></li>
              <li class="hr3"></li>
              <li class="hr4"></li>
              <li class="hr5"></li>
            </ul>
            <div class="recorder-stop">
              <span></span>
            </div>
            <ul class="start-taste-line">
              <li class="hr1"></li>
              <li class="hr2"></li>
              <li class="hr3"></li>
              <li class="hr4"></li>
              <li class="hr5"></li>
            </ul>
          </div>
        </div>
      </div>
      <el-divider v-if="hasResult"></el-divider>
      <div v-if="hasResult" class="result-detail">
        <div class="result-overall">
          <div class="text">Overall</div>
          <div class="play-replay">
            <span>{{ overall }}</span>
            <img v-if="!ifplayAudio_replay" @click="playAudio_replay" src="~@/assets/images/soe_demo/demo/play_audio.png" alt="">
            <img v-if="ifplayAudio_replay" @click="stopAudio_replay" src="~@/assets/images/soe_demo/demo/stop_audio.png" alt="">
            <audio ref="audioElement_replay" @ended="onAudioEnded_replay" :src="audioUrl"></audio>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Phoneme',
  data () {
    return {
      soe: null,

      startSoe: false,
      second: 15,
      loanTime: '',

      phonemeDataList: [
        {
          dj: '/a:/',
          audio: 'https://smart-speech.com/res/miniprogram/en/phoneme/aa.mp3',
          gist: 'Open your mouth wide, retract your tongue, and keep the tip of your tongue away from your lower teeth. When pronouncing, slightly prolong the sound.',
          words: 'father、large、class',
          refText: 'aa',
        },
        {
          dj: '/æ/',
          audio: 'https://smart-speech.com/res/miniprogram/en/phoneme/ae.mp3',
          gist: 'Touch the tip of your tongue to the back of your lower teeth, slightly lift the front part of your tongue towards the hard palate, separate your lips as much as possible to the sides, and open your mouth wide.',
          words: 'battle、apple、family',
          refText: 'ae',
        },
        {
          dj: '/b/',
          audio: 'https://smart-speech.com/res/miniprogram/en/phoneme/b.mp3',
          gist: 'When pronouncing, first close your lips tightly, hold your breath, then open your mouth, and the airflow rushes out of your mouth, making a burst sound.',
          words: 'boss、bring、rob',
          refText: 'b',
        },
        {
          dj: '/e/',
          audio: 'https://smart-speech.com/res/miniprogram/en/phoneme/eh.mp3',
          gist: 'Slightly separate your lips to the sides, gently touch the tip of your tongue to the back of your lower teeth, and slightly arch the front part of your tongue.',
          words: 'bed、net、red',
          refText: 'eh',
        },
        {
          dj: '/ɜ:/',
          audio: 'https://smart-speech.com/res/miniprogram/en/phoneme/er.mp3',
          gist: 'Place your tongue flat, extend the middle part of your tongue towards the hard palate, but do not touch the hard palate, and tense your tongue muscles.',
          words: 'earn、burn、skirt',
          refText: 'er',
        },
        {
          dj: '/ɡ/',
          audio: 'https://smart-speech.com/res/miniprogram/en/phoneme/g.mp3',
          gist: 'Rise the back of your tongue tightly against the soft palate, hold your breath, then suddenly separate, and send the airflow out of your mouth to form a burst sound.',
          words: 'good、gossip、egg',
          refText: 'g',
        },
        {
          dj: '/ɪ/',
          audio: 'https://smart-speech.com/res/miniprogram/en/phoneme/ih.mp3',
          gist: 'Raise the front part of your tongue towards the hard palate, press the sides of your tongue against the sides of your upper teeth, and stretch your lips outwards.',
          words: 'sit、hit、is',
          refText: 'ih',
        },
        {
          dj: '/i:/',
          audio: 'https://smart-speech.com/res/miniprogram/en/phoneme/iy.mp3',
          gist: 'Touch the tip of your tongue to your lower teeth, raise your front tongue as much as possible, and flatten your mouth shape.',
          words: 'easy、bee、flee',
          refText: 'iy',
        },
      ],
      selectPhonemeData: [
        {
          dj: '/a:/',
          audio: 'https://smart-speech.com/res/miniprogram/en/phoneme/aa.mp3',
          gist: 'Open your mouth wide, retract your tongue, and keep the tip of your tongue away from your lower teeth. When pronouncing, slightly prolong the sound.',
          words: 'father、large、class',
          refText: 'aa',
        }
      ],

      ifplayAudio: false,
      ifplayAudio_replay: false,

      recorderStatus: 1,

      hasResult: false,
      overall: '',
      audioUrl: '',
    }
  },
  mounted() {
    this.soe = new this.$SoeEngine({
      preService: this.$globalParam.preService,
      log: this.$globalParam.log,
      appId: this.$globalParam.appId,
      appSecret: this.$globalParam.appSecret,
      coreType: {
        langType: 'en-US',
        format: 'mp3', // 实时录音时可不传值；上传文件时传音频格式，支持 mp3、wav、pcm
        sampleRate: 16000, // 音频采样率（Hz）：当前仅支持 16000
        looseness: 4, // 评分宽松度，范围：0-9，数值越大越宽松
        connectTimeout: 15, // 连接超时时间（秒），范围：5-60
        responseTimeout: 15, // 响应超时时间（秒），范围：5-60
        scale: 100, // 评分分制，范围：1-100
        ratio: 1, // 评分调节系数，范围：0.8-1.5
        userId: '',
        realtime: false,
        audioUrl: true,
      },
      params: {
        mode: "", // 评测模式：基础题型 word/sentence/chapter  高阶题型 qa/topic/retell
        // 基础题型（词、句、篇章）
        refText: "", // 测评对照阅读的文本：
        // 高阶题型（问答题）
        stem: { // 题干
          para: "", // 段落内容
          question: "", // 提问内容
        },
        distractor: [],
        // 高阶题型（问答题）&& 高阶题型（看图说话/口语作文）&& 高阶题型（复述）
        answer: [],
        keyword: [],
      },
      micAllowCallback: () => {
        console.log('=====The microphone has been allowed.=====');
      },
      micForbidCallback: (msg) => {
        console.error('====='+msg+'=====');
        this.recorderStatus = 1
        this.startSoe = false
        this.$message.error({message: msg, duration: '2000',})
      },
      engineFirstInitDone: () => {
        console.log('=====Initialization successful.=====');
      },
      engineFirstInitFail: (status,msg) => {
        console.error('====='+status+':'+msg+'=====');
        this.recorderStatus = 1
        this.$message.error({message: msg, duration: '2000',})
      },
      getStarted: () => {
        this.recorderStatus = 2
        this.loanTime = setInterval(() => {
          this.second--
          if (this.second <= 0) {
            clearInterval(this.loanTime)
            this.loanTime = ''
            this.second = 15
            this.stopEvaluation()
          }
        }, 1000)
      },
      getRealtimeResult: (msg) => {
        console.log(msg);
      },
      engineBackResultDone: (msg) => {
        this.recorderStatus = 1
        console.log(msg);
        this.getResult(msg)
      },
      engineBackResultWarning: (status,msg) => {
        console.log(status,msg)
      },
      engineBackResultError: (status,msg) => {
        console.log(status,msg)
        this.recorderStatus = 1
        this.startSoe = false
        this.$message.error({message: msg, duration: '2000',})
      },
      playAudioComplete: () => {
        console.log('Playback complete.');
      },
      playAudioError: () => {
        this.recorderStatus = 1
        this.$message.error({message: "Playback error", duration: '2000',})
      },
      micVolumeCallback: (data) => {
        console.log('Recording volume level is:' + data);
      },
      noNetwork: () => {
        this.recorderStatus = 1
        this.$message.error({message: "No network available", duration: '2000',})
      },
      recorderBlob: (blob) => {
        console.log(blob)
      },
    })
  },
  methods: {
    // 试听音频播放
    playAudio() {
      const audioElement = this.$refs.audioElement;
      audioElement.play();
      this.ifplayAudio = true
    },
    stopAudio() {
      const audioElement = this.$refs.audioElement;
      audioElement.pause();
      audioElement.currentTime = 0;
      this.ifplayAudio = false
    },
    onAudioEnded() {
      this.ifplayAudio = false
    },

    // 切换内容
    getRandomArrayElements(arr, count) {
      const audioElement = this.$refs.audioElement;
      audioElement.pause();
      audioElement.currentTime = 0;
      this.ifplayAudio = false
      this.hasResult = false

      let shuffled = arr.slice(0), i = arr.length, min = i - count, temp, index;
      while (i-- > min) {
        index = Math.floor((i + 1) * Math.random());
        temp = shuffled[index];
        shuffled[index] = shuffled[i];
        shuffled[i] = temp;
      }
      if (shuffled.slice(min)[0].dj === this.selectPhonemeData[0].dj) {
        this.getRandomArrayElements(arr, count)
        return
      }
      this.selectPhonemeData = shuffled.slice(min)
    },

    // 开始评测
    startEvaluation() {
      this.recorderStatus = 0
      this.soe.params.mode = "phoneme";
      this.soe.params.refText = this.selectPhonemeData[0].refText;
      let params = this.soe.params;
      this.soe.startRecord(params);
      this.startSoe = true
    },
    // 结束评测
    stopEvaluation() {
      clearInterval(this.loanTime)
      this.loanTime = ''
      this.second = 15
      this.soe.stopRecord();
      this.startSoe = false
    },
    // 处理评测结果
    getResult(data) {
      this.overall = data.overall.toFixed(0)
      this.audioUrl = data.audioUrl
      this.hasResult = true
    },
    playAudio_replay() {
      const audioElement_replay = this.$refs.audioElement_replay;
      audioElement_replay.play();
      this.ifplayAudio_replay = true
    },
    stopAudio_replay() {
      const audioElement_replay = this.$refs.audioElement_replay;
      audioElement_replay.pause();
      audioElement_replay.currentTime = 0;
      this.ifplayAudio_replay = false
    },
    onAudioEnded_replay() {
      this.ifplayAudio_replay = false
    },
  }
}
</script>

<style scoped lang="less">
.page-bg {
  border: 1px solid transparent;
}
.demo-main {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  width: 1140px;
  padding: 88px 30px 30px;
  margin: 30px auto;
  background: #fff;
  box-shadow: 0 0 15px 0 hsla(0,0%,79.6%,.5);
  border-radius: 10px;
  .breadcrumb {
    position: absolute;
    left: 30px;
    top: 30px;
    margin-bottom: 30px;
  }
  .title {
    line-height: 38px;
    color: #000;
    text-align: center;
    font-weight: 500;
    font-size: 30px;
  }
  .sub-title {
    line-height: 28px;
    margin: 70px auto 0;
    color: #333;
    text-align: center;

    font-size: 20px;
  }
  .countdown {
    position: absolute;
    right: 30px;
    top: 30px;
    width: 40px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    border-radius: 100%;
    background: linear-gradient(135deg, #2D91FA 0%, #2050E5 100%);
    color: #fff;
  }
  .demo-container {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 40px 0 0;
    .ref-text-content {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 1040px;
      height: 58px;
      margin: 0 auto;
      .ref-text {
        display: flex;
        align-items: center;
        position: relative;
        font-weight: 500;
        font-size: 40px;
        color: #333;
        img {
          position: absolute;
          right: -50px;
          top: 10px;
          width: 30px;
          cursor: pointer;
        }
      }
    }
    .ref-text-tip {
      width: 50%;
      margin: 30px auto 0;

      p {
        margin-bottom: 10px;
      }
    }
    .btn-try-next-box {
      margin-top: 50px;
      /deep/ .el-button--primary.is-plain {
        background: transparent;
        border-color: #2876F1;
        color: #2876F1;
        font-weight: 500;
      }
    }
    .btn-recorder {
      position: relative;
      height: 80px;
      margin-top: 23px;
      cursor: pointer;
      .recorder-box {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 80px;
        height: 80px;
        border-radius: 100%;
        background: linear-gradient(135deg, #2D91FA 0%, #2050E5 100%);
        box-shadow: 0 2px 6px 0 rgba(0,110,254,0.4);
        cursor: pointer;
        img {
          width: 50%;
        }
      }
      .recorder-box-loading {
        background: #DAEAFF;
        .loading-taste-line {
          position: absolute;
          width: 40px;
          height: 40px;
        }
        .item{
          position: absolute;
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: flex-end;
          animation: xuanzhuan 4s linear infinite;
        }
        .item:nth-child(1){
          animation-delay: 0.15s;
        }
        .item:nth-child(2){
          animation-delay: 0.3s;
        }
        .item:nth-child(3){
          animation-delay: 0.45s;
        }
        .item:nth-child(4){
          animation-delay: 0.6s;
        }
        .item:nth-child(5){
          animation-delay: 0.75s;
        }
        .item::after{
          content:'';
          display: block;
          width: 5px;
          height: 5px;
          border-radius: 50%;
          background: royalblue;
        }
        @keyframes xuanzhuan{
          75%{
            transform: rotate(650deg);
          }
          79%{
            transform: rotate(720deg);
            opacity: 1;
          }
          80%{
            transform: rotate(720deg);
            opacity: 0;
          }
          100%{
            transform: rotate(810deg);
            opacity: 0;
          }
        }
      }
      .recorder-box-stop {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 240px;
        height: 80px;
        .recorder-stop {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 80px;
          height: 80px;
          margin: 0 10px;
          border-radius: 100%;
          background: linear-gradient(135deg, #2D91FA 0%, #2050E5 100%);
          box-shadow: 0 2px 6px 0 rgba(0,110,254,0.4);
          cursor: pointer;
          span {
            width: 22px;
            height: 22px;
            background: #fff;
            border-radius: 3px;
          }
        }
        .start-taste-line {
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .start-taste-line li {
          background: linear-gradient(135deg, #2D91FA 0%, #2050E5 100%);
          width: 4px;
          height: 6px;
          margin: 0 2px;
          display: inline-block;
          border: none;
          border-radius: 1px;
        }
        li {
          animation: note 0.3s ease-in-out;
          animation-iteration-count: infinite;
          animation-direction: alternate;
        }
        .hr1 {
          animation-delay: -1s;
        }
        .hr2 {
          animation-delay: -0.9s;
        }
        .hr3 {
          animation-delay: -0.8s;
        }
        .hr4 {
          animation-delay: -0.9s;
        }
        .hr5 {
          animation-delay: -1s;
        }
        @keyframes note {
          from {
            transform: scaleY(1);
          }
          to {
            transform: scaleY(5);
          }
        }
      }
    }
  }
  .result-detail {
    width: 100%;
    .result-overall {
      display: flex;
      align-items: center;
      flex-direction: column;
      .text {
        font-size: 20px;
        color: #1377e1;
        line-height: 25px;
      }
      .play-replay {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 160px;
        height: 70px;
        margin-top: 10px;
        background: #F5F9FF;
        border-radius: 36px;
        span {
          height: 40px;
          line-height: 30px;
          margin-right: 10px;
          font-weight: 500;
          font-size: 40px;
          color: #1377e1;
        }
        img {
          width: 30px;
          cursor: pointer;
        }
      }
    }
  }
}
</style>